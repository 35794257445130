import {IsNotEmpty, IsString, MaxLength} from 'class-validator';
import {QuickForm} from '../../components/QuickEditor';

export const promptCharLimit = 256;

export type QuickEditGETResponse = {
  linear_form: QuickForm;
  onboarding_id: string;
  form_id?: string;
  prompt?: string;
};

export type QuickEditPATCHRequest = {
  linear_form: QuickForm;
  onboarding_id?: string;
  prompt?: string;
};

export class GenerateFormPUTRequest {
  @IsString()
  @MaxLength(promptCharLimit)
  @IsNotEmpty()
  prompt: string = '';

  @IsString()
  language?: string;

  @IsString()
  page_count?: string;
}
