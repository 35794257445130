import React, {useEffect} from 'react';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import PromptPage from './components/Page/PromptPage';
import SharedLayout from './components/SharedLayout';
import QuickEditPage from './components/Page/QuickEditPage';
import {useAppContext} from './AppContext';
import FormPreviewPage from './components/Page/FormPreviewPage';
import Prompt from './components/Prompt';
import PDFPage from './components/Page/PDFPage';
import useTitle from './hooks/useTitle';
import useDescription from './hooks/useDescription';

const router = createBrowserRouter([
  {
    path: '/',
    loader: () => ({ message: 'main' }),
    Component() {
      const { getForm, isMobileWidth, setSource, source } = useAppContext();
      const form = getForm();

      useEffect(() => {
        if (source === 'ai') return;
        setSource('ai');
      }, [source, setSource]);

      if (source !== 'ai') return null;

      if (form?.form_id) {
        return (
          <SharedLayout
            header={'preview'}
            subHeaderComponent={<Prompt column={isMobileWidth} />}
          >
            <FormPreviewPage />
          </SharedLayout>
        );
      }

      return (
        <SharedLayout showGradientBackground verticalCenter>
          <PromptPage />
        </SharedLayout>
      );
    }
  },
  {
    path: '/edit',
    loader: () => ({ message: 'editing' }),
    Component() {
      return (
        <SharedLayout header={'edit'}>
          <QuickEditPage />
        </SharedLayout>
      );
    }
  },
  {
    path: '/pdf',
    loader: () => ({ message: 'migrating' }),
    Component() {
      const { setSource, getForm, isMobileWidth, source } = useAppContext();
      const form = getForm();

      useTitle('PDF Migration Tool | Feathery');
      useDescription(
        'Convert your PDF into a digital form with Feathery for free and in seconds.'
      );

      useEffect(() => {
        if (source === 'pdf') return;
        setSource('pdf');
      }, [source, setSource]);

      if (source !== 'pdf') return null;

      if (form?.form_id) {
        return (
          <SharedLayout
            header={'preview'}
            subHeaderComponent={<Prompt column={isMobileWidth} source={source} />}
          >
            <FormPreviewPage />
          </SharedLayout>
        );
      }

      return (
        <SharedLayout showGradientBackground verticalCenter>
          <PDFPage />
        </SharedLayout>
      );
    }
  }
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default Router;
