import React, { useEffect } from 'react';
import { getCookie, setCookie } from './utils';
import { QuickForm } from './components/QuickEditor';
import api, { QuickEditGETResponse } from './api';

type formResponses = {
  ai: QuickEditGETResponse | null;
  pdf: QuickEditGETResponse | null;
};

const defaultOnboardingId = getCookie('featheryOnboardingId') || '';
const defaultSource = (getCookie('featheryOnboardingSource') || 'ai') as keyof formResponses;

const defaultFormResponse = {
  ai: null,
  pdf: null
};

const AppContext = React.createContext<{
  onboardingId: string;
  showSaveModal: boolean;
  prompt: string;
  lockout: boolean;
  setFormData: (formData: QuickForm) => void;
  setPrompt: (prompt: string) => void;
  setShowSaveModal: (showSaveModal: boolean) => void;
  setOnboardingId: (onboardingId: string) => void;
  setLockout: (lockout: boolean) => void;
  formData: QuickForm;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  formResponse: formResponses;
  setFormResponse: any;
  isMobileWidth: boolean;
  isDrawerOpen: boolean;
  setIsDrawerOpen: (isDrawerOpen: boolean) => void;
  source: string;
  setSource: (source: 'ai' | 'pdf') => void;
  getForm: () => QuickEditGETResponse | null;
}>({
  onboardingId: '',
  showSaveModal: false,
  prompt: '',
  lockout: false,
  formData: {} as QuickForm,
  setPrompt: () => {},
  setShowSaveModal: () => {},
  setOnboardingId: () => {},
  setLockout: () => {},
  setFormData: () => {},
  isLoading: false,
  setIsLoading: () => {},
  formResponse: defaultFormResponse,
  setFormResponse: () => {},
  isMobileWidth: false,
  isDrawerOpen: false,
  setIsDrawerOpen: () => {},
  source: '',
  setSource: () => {},
  getForm: () => null
});

const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [onboardingId, setOnboardingId] = React.useState(defaultOnboardingId);
  const [showSaveModal, setShowSaveModal] = React.useState(false);
  const [prompt, setPrompt] = React.useState('');
  const [lockout, setLockout] = React.useState(false);
  const [formData, setFormData] = React.useState<QuickForm>({} as QuickForm);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isMobileWidth, setIsMobileWidth] = React.useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [source, setSource] = React.useState<keyof formResponses>(defaultSource);

  const [formResponse, updateFormResponse] =
    React.useState<formResponses>(defaultFormResponse);

  const getForm = () => formResponse[source] ?? null;

  const setFormResponse = React.useCallback(
    (res: QuickEditGETResponse | null) => {
      updateFormResponse((prevFormResponse) => {
        if (!res) return prevFormResponse;

        window.history.replaceState(
          '',
          document.title,
          window.location.pathname
        );
        return {
          ...prevFormResponse,
          [source]: res
        };
      });
    },
    [source]
  );

  useEffect(() => {
    if (!onboardingId) return;
    setCookie('featheryOnboardingId', onboardingId as string);
    api.getForm({ onboardingId, source }).then((res) => {
      setFormResponse(res);
    });
  }, [onboardingId, setFormResponse, source]);

  useEffect(() => {
    if (getCookie('featheryOnboardingSource') === source) return;
    setCookie('featheryOnboardingSource', source as string);
  }, [source]);

  useEffect(() => {
    // Watch window size
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobileWidth(true);
      } else {
        setIsMobileWidth(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <AppContext.Provider
      value={{
        onboardingId,
        showSaveModal,
        prompt,
        lockout,
        formData,
        isLoading,
        formResponse,
        setPrompt,
        setShowSaveModal,
        setOnboardingId,
        setLockout,
        setFormData,
        setIsLoading,
        setFormResponse,
        isMobileWidth,
        isDrawerOpen,
        setIsDrawerOpen,
        source,
        setSource,
        getForm
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => React.useContext(AppContext);

export { AppContextProvider, useAppContext };
