import React from 'react';
import styles from './styles.module.scss';
import { Dropdown, option } from '../Dropdown';
import { useAppContext } from '../../AppContext';
import api, { promptCharLimit } from '../../api';
import classNames from 'classnames';
import Button from '../Button';

const Prompt = ({
  column,
  source = 'ai',
  primary
}: {
  column?: boolean;
  source?: 'ai' | 'pdf';
  primary?: boolean;
}) => {
  const {
    prompt,
    setPrompt,
    onboardingId,
    setOnboardingId,
    lockout,
    setLockout
  } = useAppContext();

  const [file, setFile] = React.useState<File | null>(null);
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const [language, setLanguage] = React.useState<string>('en');
  const [pageNumber, setPageNumber] = React.useState<string>('any');
  const { setIsLoading, getForm, setFormResponse } = useAppContext();

  const formRef = React.useRef<HTMLFormElement>(null);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const onSubmitPDF = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (lockout || file == null) return;

    setLockout(true);
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append('pdf', file);
      const res = await api.migratePDFFormWithAI(formData, onboardingId);
      if (res.error) return handleError(res.error);
      if (res.onboardingId) {
        const form = await api.getForm({
          onboardingId: res.onboardingId,
          source: 'pdf'
        });
        setFormResponse(form);
        setLockout(false);
        setIsLoading(false);
        setOnboardingId(res.onboardingId);
      }
    } catch (error) {
      console.error('Error migrating the PDF:', error);
      return handleError('Error migrating the PDF')
    }
  };

  const languageOptions = [
    option('English', 'en'),
    option('Spanish', 'es'),
    option('French', 'fr'),
    option('German', 'de'),
    option('Italian', 'it'),
    option('Hebrew', 'he'),
    option('Portuguese', 'pt'),
    option('Russian', 'ru'),
    option('Chinese', 'zh'),
    option('Japanese', 'ja'),
    option('Korean', 'ko'),
    option('Arabic', 'ar'),
    option('Hindi', 'hi'),
    option('Turkish', 'tr')
  ];

  const pageNumberOptions = [
    option('Any', 'any'),
    option('1', '1'),
    option('2', '2'),
    option('3', '3'),
    option('4', '4'),
    option('5', '5')
  ];

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt(e.target.value);
  };

  const handleError = (errorMessage: string) => {
    setLockout(false);
    setIsLoading(false);
    return setAndReportValidity(errorMessage);
  };

  const onGenerate = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (lockout) return;
    setLockout(true);
    setIsLoading(true);
    const res = await api.generateForm({
      request: {
        prompt,
        language,
        page_count: pageNumber
      },
      onboardingId
    });
    if (res.error) return handleError(res.error);
    if (res.onboardingId) {
      const form = await api.getForm({
        onboardingId: res.onboardingId,
        source: 'ai'
      });
      setFormResponse(form);
      setLockout(false);
      setIsLoading(false);
      setOnboardingId(res.onboardingId);
    }
  };

  const setAndReportValidity = (value: string) => {
    // Set custom validity on prompt input
    const form = formRef.current;
    if (!form) return;
    const input: HTMLInputElement = form.elements.namedItem(
      'error-output'
    ) as HTMLInputElement;
    if (!input) return;
    input.setCustomValidity(value);
    formRef.current?.reportValidity();
    return;
  };

  const defaultPlaceholder = 'Describe your form';

  const placeholder = getForm()?.prompt || defaultPlaceholder;

  const promptCharCount = prompt?.length || 0;

  return (
    <div className={styles.promptWrapper}>
      <form
        ref={formRef}
        className={classNames(
          styles.promptContainer,
          column && styles.promptContainerColumn,
          source === 'pdf' && styles.pdfPrompt
        )}
      >
        <input
          id='error-output'
          // Set to file type so keyboard doesn't pop up on mobile
          // when field error appears
          type='file'
          style={{
            position: 'absolute',
            opacity: 0,
            top: '50%',
            left: '50%',
            width: '1px',
            height: '1px',
          }}
        />
        {source !== 'pdf' && (
          <div className={styles.promptInputContainer}>
            <input
              className={styles.promptInput}
              type={'text'}
              value={prompt}
              onChange={onChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              placeholder={isFocused ? '' : placeholder}
            />

            {!!promptCharCount && source === 'ai' && (
              <div
                className={classNames(
                  styles.promptCharCount,
                  promptCharCount > promptCharLimit && styles.promptOverLimit
                )}
              >
                {promptCharCount}/{promptCharLimit}
              </div>
            )}
          </div>
        )}
        {source === 'ai' && (
          <div className={styles.optionsRow}>
            <Dropdown
              label={'Language'}
              options={languageOptions}
              value={language}
              onChange={setLanguage}
            />
            <Dropdown
              label={'Pages'}
              options={pageNumberOptions}
              value={pageNumber}
              onChange={setPageNumber}
            />
            <Button
              className={styles.responsive}
              primary={column}
              onClick={onGenerate}
            >
              Generate
            </Button>
          </div>
        )}

        {source === 'pdf' && (
          <div className={styles.pdfInputContainer}>
            <input
              type='file'
              accept='.pdf'
              className={styles.pdfInput}
              onChange={onFileChange}
            />
            <Button
              className={styles.responsive}
              primary={column || primary}
              onClick={onSubmitPDF}
            >
              Migrate
            </Button>
          </div>
        )}
      </form>
    </div>
  );
};

export default Prompt;
