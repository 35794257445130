import React from 'react';
import QuickQuestionEditor from '../QuickQuestionEditor';
import EditableText from '../EditableText';
import styles from './styles.module.scss';
import AddBar from '../AddBar';
import { QuickField, QuickOption, QuickPage } from '../QuickEditor';
import DeleteButton from '../DeleteButton';
import QuickOptionsEditor from '../QuickOptionsEditor';
import Drawer from '../Drawer';
import { v4 } from 'uuid';
import OtherOptionsEditor from '../OtherOptionsEditor';

const QuickPageEditor = ({
  index,
  page: originalPage,
  onDelete,
  onChange,
  showDeleteButton = true
}: {
  index: number;
  page: QuickPage;
  onDelete: (uuid: string) => void;
  onChange: (uuid: string | undefined, page: QuickPage) => void;
  showDeleteButton: boolean;
}) => {
  const [page, setPage] = React.useState(originalPage);

  const updatePage = (page: QuickPage) => {
    onChange(page.uuid, page);
  };

  const deleteField = (questionIndex: number) => {
    setPage((page) => {
      const newFields = [...page.questions];
      newFields.splice(questionIndex, 1);
      updatePage({ ...page, questions: newFields });
      return { ...page, questions: newFields };
    });
  };

  const addField = (questionIndex: number) => {
    setPage((page) => {
      const newFields = [...page.questions];
      newFields.splice(questionIndex + 1, 0, {
        question_text: 'New Field',
        type: 'text',
        uuid: v4()
      });
      updatePage({ ...page, questions: newFields });
      return { ...page, questions: newFields };
    });
  };

  const removeAndInsert = (uuidA: string, uuidB: string) => {
    setPage((page) => {
      const questions = page.questions;
      const indexA = questions.findIndex((q) => q.uuid === uuidA);
      const indexB = questions.findIndex((q) => q.uuid === uuidB);
      const newFields = [...questions];
      const temp = newFields[indexA];
      newFields.splice(indexA, 1);
      newFields.splice(indexB, 0, temp);
      updatePage({ ...page, questions: newFields });
      return { ...page, questions: newFields };
    });
  };

  const removeAndInsertOptions = (
    questionUuid: string,
    uuidA: string,
    uuidB: string
  ) => {
    // @ts-ignore
    setPage((page) => {
      const questions = page.questions;
      const newFields = [...questions];
      const questionIndex = newFields.findIndex((q) => q.uuid === questionUuid);
      const question = newFields[questionIndex];
      if (!question.options) return newFields;
      // @ts-ignore
      const newOptions: QuickOption[] = [...question.options];
      const indexA = newOptions.findIndex((o) => o.uuid === uuidA);
      const indexB = newOptions.findIndex((o) => o.uuid === uuidB);
      const temp = newOptions[indexA];
      newOptions.splice(indexA, 1);
      newOptions.splice(indexB, 0, temp);

      // set new options
      newFields[questionIndex] = {
        ...question,
        options: newOptions
      };

      updatePage({ ...page, questions: newFields });
      return { ...page, questions: newFields };
    });
  };

  const onChangeQuestion = (questionIndex: number, question: QuickField) => {
    setPage((page) => {
      const questions = page.questions;
      const newFields = [...questions];
      newFields[questionIndex] = question;
      updatePage({ ...page, questions: newFields });
      return { ...page, questions: newFields };
    });
  };

  const onChangePageTitle = (value: string) => {
    setPage((page) => {
      const newPage = { ...page, page_title: value };
      onChange(page.uuid, newPage);
      return newPage;
    });
  };

  const typeHasOptions = (type: string) => {
    if (['radio', 'checkbox', 'dropdown'].includes(type)) {
      return true;
    }
  };

  return (
    <div className={styles.stepContainer}>
      <div className={styles.titleContainer}>
        <div className={styles.pageTitle}>
          <EditableText text={page.page_title} onChange={onChangePageTitle} />
        </div>
        <DeleteButton onClick={onDelete} enabled={showDeleteButton} />
      </div>
      {page.questions.map((question, i) => {
        if (!question) return null;

        const onDelete = () => deleteField(i);
        const onClickAdd = () => addField(i);

        const onChange = (question: QuickField) =>
          onChangeQuestion(i, question);

        return (
          <React.Fragment key={question.uuid}>
            <div className={styles.questionWrapper}>
              <QuickQuestionEditor
                question={question}
                key={question.uuid + 'question'}
                pageIndex={index}
                onDelete={onDelete}
                onDrop={removeAndInsert}
                onChange={onChange}
                showDeleteButton={page.questions.length > 1}
              />
              {typeHasOptions(question.type) && (
                <>
                  <Drawer
                    title={'Field Options'}
                    openByDefault={true}
                    onClose={() => {}}
                    onOpen={() => {}}
                  >
                    <QuickOptionsEditor
                      key={question.uuid + 'options'}
                      question={question}
                      onChange={onChange}
                      onDrop={removeAndInsertOptions}
                      questionIndex={i}
                    />
                  </Drawer>
                </>
              )}
              <>
                <Drawer
                  title={'Other Options'}
                  openByDefault={false}
                  onClose={() => {}}
                  onOpen={() => {}}
                >
                  <OtherOptionsEditor
                    key={question.uuid + 'other'}
                    question={question}
                    onChange={onChange}
                  />
                </Drawer>
              </>
            </div>
            <AddBar onClick={onClickAdd} />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default QuickPageEditor;
