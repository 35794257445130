import React from 'react';
import { Dropdown, option } from '../Dropdown';
import styles from './styles.module.scss';
import DragIcon from '../Icons/DragIcon';
import DeleteButton from '../DeleteButton';
import TextInput from '../TextInput';
import { QuickField } from '../QuickEditor';
import useDragAndDrop from '../../hooks/useDragAndDrop';
import classNames from 'classnames';

const aiFieldTypes = [
  option('Text', 'text'),
  option('Text Area', 'textarea'),
  option('Radio', 'radio'),
  option('Checkbox', 'checkbox'),
  option('Email', 'email'),
  option('Number', 'number'),
  option('Dropdown', 'dropdown'),
  option('Phone', 'phone'),
  option('File', 'file')
];

const QuickQuestionEditor = ({
  question,
  onChange,
  pageIndex,
  onDelete,
  onDrop,
  showDeleteButton = true,
}: {
  question: QuickField;
  onChange: (question: QuickField) => void;
  pageIndex: number;
  onDelete: () => void;
  onDrop: (uuidA: string, uuidB: string) => void;
  showDeleteButton: boolean;
}) => {
  const { drag, dragPreview, drop, isOver } = useDragAndDrop(
    question.uuid || '',
    `PAGE-${pageIndex}-QUESTION`,
    onDrop
  );

  const dragAndDropRefs = (element: any) => {
    dragPreview(element);
    drop(element);
  };

  const dragHandleRef = (element: any) => {
    drag(element);
  };

  const onChangeLabel = (label: string) => {
    onChange({ ...question, question_text: label });
  };

  const onChangeType = (type: string) => {
    onChange({ ...question, type });
  };

  return (
    <div
      ref={dragAndDropRefs}
      className={classNames(styles.quickField, isOver && styles.isOver)}
      style={{
        padding: 15
      }}
    >
      <div
        style={{
          padding: 15,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        ref={dragHandleRef}
      >
        <DragIcon />
      </div>
      <div className={styles.group}>
        <label>Label</label>
        <TextInput value={question.question_text} onChange={onChangeLabel} />
      </div>
      <div className={styles.group}>
        <label>Type</label>
        <Dropdown
          hideLabel
          value={question.type}
          label={'Text'}
          options={aiFieldTypes}
          onChange={onChangeType}
        />
      </div>
      {showDeleteButton && (
        <DeleteButton enabled={showDeleteButton} onClick={onDelete} />
      )}
    </div>
  );
};
export default QuickQuestionEditor;
